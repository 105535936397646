<template>
  <b-table
    id="clinic-user-table"
    class="basic-table"
    :class="{ 'clickable-table': rowClickSupported }"
    no-sort-reset
    :fields="tableFieldsProcessed"
    :items="clinicUsersProcessed"
    :sort-by="sortKey"
    :sort-compare="sortCompare"
    @row-clicked="rowClickHandler"
  >
    <template #cell(name)="data">
      <div class="td-multiple-spans">
        <span>
          {{ formatClinicUserName(data.item) }}
        </span>
        <span class="clinic-user-adminstrator-label-list">
          {{
            isClinicUserAdmin(data.item, clinicId)
              ? $t("clinicalUser.userLabelAdministrator")
              : ""
          }}
        </span>
      </div>
    </template>
    <template #cell(resend)="data">
      <span>
        <button
          type="button"
          class="button-link"
          @click.prevent="resendInvite(data.item)"
        >
          {{ $t("clinicalUser.tableHeaderResend") }}
        </button>
      </span>
    </template>
    <template #cell(manage)="data">
      <span v-if="data.item.active">
        <button
          type="button"
          class="button-link button-remove"
          @click.prevent="deactivate(data.item)"
        >
          X
        </button>
      </span>
      <span v-else-if="!data.item.active">
        <button
          type="button"
          class="button-link"
          @click.prevent="activate(data.item)"
        >
          &#10003;
        </button>
      </span>
    </template>
  </b-table>
</template>
<script>
export default {
  props: {
    clinicId: {
      type: Number,
      required: true,
    },
    clinicUsers: {
      type: Array,
      required: true,
    },
    showInactive: {
      type: Boolean,
      default: true,
    },
    sortable: {
      type: Boolean,
      default: true,
    },
    sortKey: {
      type: String,
      default: "name",
    },
    rowClickSupported: {
      type: Boolean,
      default: true,
    },
    showRowState: {
      type: Boolean,
      default: true,
    },
    maxRows: {
      type: Number,
      default: null,
    },
  },
  data: function () {
    return {
      tableFields: [
        {
          key: "name",
          label: this.$t("clinicalUser.tableHeaderName"),
          sortDirection: "asc",
          sortable: this.sortable,
        },
        {
          key: "cognitoemail",
          label: this.$t("clinicalUser.tableHeaderEmail"),
          formatter: this.emailFormatter,
          sortDirection: "asc",
          sortable: this.sortable,
        },
        {
          key: "create_ts",
          label: this.$t("clinicalUser.tableHeaderCreated"),
          formatter: this.dateFormatter,
          sortDirection: "desc",
          sortable: this.sortable,
        },
        {
          key: "lastlogin",
          label: this.$t("clinicalUser.tableHeaderLastLogin"),
          formatter: this.dateFormatter,
          sortDirection: "desc",
          sortable: this.sortable,
        },
        {
          key: "active",
          label: this.$t("clinicalUser.tableHeaderActive"),
          formatter: this.activeFormatter,
          sortDirection: "asc",
          sortable: this.sortable,
        },
        { key: "resend", label: this.$t("clinicalUser.tableHeaderResend") },
        { key: "manage", label: this.$t("clinicalUser.tableHeaderManage") },
      ],
    };
  },
  computed: {
    tableFieldsProcessed: function () {
      if (!this.showInactive) {
        return this.tableFields.filter(function (el) {
          return el.key !== "active";
        });
      } else {
        return this.tableFields;
      }
    },
    clinicUsersProcessed: function () {
      if (!this.clinicUsers) {
        return [];
      }

      var clinicUsersProcessed = this.clinicUsers;

      if (!this.showInactive) {
        clinicUsersProcessed = clinicUsersProcessed.filter(function (el) {
          return el.active;
        });
      }

      if (this.maxRows !== null) {
        clinicUsersProcessed = clinicUsersProcessed.sort((a, b) => {
          return this.sortCompare(
            a,
            b,
            "name",
            "asc",
            undefined,
            { numeric: true },
            undefined
          );
        });

        clinicUsersProcessed = clinicUsersProcessed.slice(0, this.maxRows);
      }

      if (!this.showRowState) {
        return clinicUsersProcessed;
      }

      return clinicUsersProcessed.map((item) => {
        item._rowVariant = this.getRowVariants(item);
        return item;
      });

      return clinicUsersProcessed;
    },
  },
  methods: {
    resendInvite(user) {
      let clinicUserId = user.id;

      this.api
        .v1ClinicuserClinicuseridResetpasswordPost(clinicUserId)
        .then(() => {
          // Show a general success message
          this.$bus.emit(
            "show-general-success",
            this.$t("clinicalUser.successInviteResent")
          );
        })
        .catch((error) => {
          // Get the error and display it
          this.showApiResponseError(
            error,
            this.$t("clinicalUser.errorResendingInvite")
          );
        });
    },
    emailFormatter: function (value, key, item) {
      if (this.fieldExists(item, key) && item[key] !== null) {
        return value;
      }

      return "-";
    },
    dateFormatter: function (value, key, item) {
      if (this.fieldExists(item, key) && item[key] !== null) {
        let formattedDate = this.dateOrEpochFormat(value, true);
        if (formattedDate) {
          return formattedDate;
        }
      }

      return "-";
    },
    activeFormatter: function (value, key, item) {
      if (this.fieldExists(item, key) && item[key] !== null) {
        return value ? this.$t("common.yes") : this.$t("common.no");
      }

      return "-";
    },
    getRowVariants: function (item) {
      if (!item.active) {
        return "row-inactive";
      }

      return "";
    },
    deactivate: function (item) {
      var eventId = "clinic-user-" + (item ? item.id : "");
      this.showConfirmationPrompt(
        this.$t("clinicalUser.promptDeactivate"),
        eventId,
        (state) => {
          if (state) {
            // Make the clinic user inactive
            this.updateClinicUserState(item, false);
          }
        }
      );
    },
    activate: function (item) {
      this.updateClinicUserState(item, true);
    },
    updateClinicUserState(clinicUser, newActiveState) {
      this.api
        .v1ClinicuserClinicuseridPut(clinicUser.id, {
          updateclinicuser: {
            active: newActiveState,
          },
        })
        .then(() => {
          // Show a general success message
          this.$bus.emit(
            "show-general-success",
            this.$t("clinicalUser.successUpdate")
          );

          // On success send out a event to indicate that a clinic user has changed
          this.$emit("refresh-list");
        })
        .catch((error) => {
          // Get the error and display it
          this.showApiResponseError(error, this.$t("clinicalUser.errorUpdate"));
        });
    },
    sortCompare: function (
      aRow,
      bRow,
      key,
      sortDesc,
      formatter,
      compareOptions,
      compareLocale
    ) {
      const a = aRow[key];
      const b = bRow[key];

      if (key === "name") {
        let compareValue = this.toString(aRow.firstname).localeCompare(
          this.toString(bRow.firstname),
          compareLocale,
          compareOptions
        );

        if (compareValue === 0) {
          compareValue = this.toString(aRow.lastname).localeCompare(
            this.toString(bRow.lastname),
            compareLocale,
            compareOptions
          );
        }

        return compareValue;
      } else {
        if (
          (typeof a === "number" && typeof b === "number") ||
          (a instanceof Date && b instanceof Date)
        ) {
          // If both compared fields are native numbers or both are native dates
          return a < b ? -1 : a > b ? 1 : 0;
        } else {
          // Otherwise stringify the field data and use String.prototype.localeCompare
          return this.toString(a).localeCompare(
            this.toString(b),
            compareLocale,
            compareOptions
          );
        }
      }
    },
    rowClickHandler: function (item) {
      this.$emit("row-clicked", item.id);
    },
  },
};
</script>
<style lang="scss">
.clinic-user-adminstrator-label-list {
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
}
</style>
